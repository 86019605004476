import idb from "../idb.js";

export default {
  async insertGeneralAtlasSquareStatistics(generalAtlasSquareStatistics) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["generalAtlasSquareStatistics"], "readwrite");
      let store = trans.objectStore("generalAtlasSquareStatistics");

      generalAtlasSquareStatistics.forEach((generalAtlasSquareStatistic) => {
        store.put(generalAtlasSquareStatistic);
      });
    });
  },

	async clearAll() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["generalAtlasSquareStatistics"], "readwrite");
      let store = trans.objectStore("generalAtlasSquareStatistics");
      store.clear();
    });
  },

  async clearAll() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["generalAtlasSquareStatistics"], "readwrite");
      let store = trans.objectStore("generalAtlasSquareStatistics");
      store.clear();
    });
  },

  async getGeneralAtlasSquareStatistics() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["generalAtlasSquareStatistics"], "readonly");
      trans.oncomplete = () => {
        resolve(generalAtlasSquareStatistics);
      };

      let store = trans.objectStore("generalAtlasSquareStatistics");
      let generalAtlasSquareStatistics = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          generalAtlasSquareStatistics.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
};
