import idb from "../idb.js";

export default {
  async insertAtlasSquares(atlasSquares) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["atlasSquares"], "readwrite");
      let store = trans.objectStore("atlasSquares");

      atlasSquares.forEach((atlasSquare) => {
        store.put(atlasSquare);
      });
    });
  },

  async getAtlasSquaresCount() {
    let count = await idb.getCountByObjectStore("atlasSquares");
    return count.result;
  },

  async getAtlasSquares() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["atlasSquares"], "readonly");
      trans.oncomplete = () => {
        resolve(atlasSquares);
      };

      let store = trans.objectStore("atlasSquares");
      let atlasSquares = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          atlasSquares.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
};
