import idb from "../idb.js";

export default {

  async insertSpeciesChecksum(checksum) {
    checksum.id=1;
    let db = await idb.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["speciesChecksum"], "readwrite");
      let store = trans.objectStore("speciesChecksum");

      store.put(checksum);
    });
  },

  async clearAll() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["speciesChecksum"], "readwrite");
      let store = trans.objectStore("speciesChecksum");
      store.clear();
    });
  },

  async getSpeciesChecksum() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["speciesChecksum"], "readonly");
      trans.oncomplete = () => {
        resolve(sites);
      };

      let store = trans.objectStore("speciesChecksum");
      let sites = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          sites.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
}