import idb from "../idb.js";

export default {
  async insertSpecie(specie) {
    let db = await idb.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["species"], "readwrite");
      let store = trans.objectStore("species");

      store.put(specie);
    });
  },

  async getSpeciesCount() {
    let count = await idb.getCountByObjectStore("species");
    return count.result;
  },

	async clearAll() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["species"], "readwrite");
      let store = trans.objectStore("species");
      store.clear();
    });
  },

  async insertSpecies(species) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["species"], "readwrite");
      let store = trans.objectStore("species");

      species.forEach((specie) => {
        store.put(specie);
      });
    });
  },

  async getSpecies() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["species"], "readonly");
      trans.oncomplete = () => {
        resolve(species);
      };

      let store = trans.objectStore("species");
      let species = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          species.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
};
