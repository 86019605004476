<template>
  <div>
    <div id="install-app-btn-container" class="mb-2 text-center">
      <button id="install-app-btn" type="button" class="btn btn-default">
        <i class="material-icons button-material-icon">
          download_for_offline
        </i>
        Installer som app
      </button>
    </div>

    <div id="pwa-browser-compability-container" class="mb-2">
      Hvis du ønsker at installere som app på din iPhone, så besøg siden via
      Safari browser, hvorfra du blot tilføjer den til din home screen. Fordelen
      ved at installere er at du hurtigt kan tilgå den, og vil kunne bruge den
      offline.
    </div>
    <div id="ios-webkit-install-instructions-container" class="mb-2">
      <p v-if="showInstaller" class="text-center">
        <button
          class="btn btn-default"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapseExample"
          aria-expanded="false"
          aria-controls="collapseExample"
        >
          <i class="material-icons button-material-icon">
            download_for_offline
          </i>
          Installer som app
        </button>
      </p>
      <div class="collapse" id="collapseExample">
        <div class="card card-body">
          <b>Sådan installerer du appen på din iPhone</b>
          <ol>
            <li>
              Tryk på
              <img class="install-icon" src="@/assets/icons/share.png" />
            </li>
            <li>
              Føj til hjemmeskærm <img class="install-icon" src="@/assets/icons/add.png" />
            </li>
            <li>Vælg "Tilføj"</li>
          </ol>
          <p class="small">
            Hvis du ikke ser det første ikon, så prøv at swipe ned for at få det fremvist i bunden af din skærm.
          </p>
        </div>
      </div>
      <p v-if="showInstaller" class="text-center">
        <a href="#" @click="promptRemovalOfInstallInstructions">Klik her</a> for
        at skjule installationsknap fremover
      </p>
    </div>
  </div>
</template>

<script>
import "@/assets/installer.js";

export default {
  data() {
    return {
      showInstaller: true,
    };
  },
  methods: {
    promptRemovalOfInstallInstructions() {
      this.hideInstallInstructions();
    },
    hideInstallInstructions() {
      $cookies.set("hideInstaller", "true", 60 * 60 * 24 * 365 * 10);
      this.showInstaller = false;
    },
  },
  mounted() {
    if ($cookies.isKey("hideInstaller") != null) {
      this.showInstaller = $cookies.get("hideInstaller") ? false : true;
    }
  },
};
if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register("/service-worker.js");
}
</script>

<style scoped>
.install-icon {
  max-height: 30px;
  max-width: 30px;
}
</style>
