import idb from "../idb.js";

export default {
  async insertSecondaryBehaviour(secondaryBehaviour) {
    let db = await idb.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["secondaryBehaviours"], "readwrite");
      let store = trans.objectStore("secondaryBehaviours");

      store.put(secondaryBehaviour);
    });
  },

	async clearAll() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["secondaryBehaviours"], "readwrite");
      let store = trans.objectStore("secondaryBehaviours");
      store.clear();
    });
  },

  async insertSecondaryBehaviours(secondaryBehaviours) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["secondaryBehaviours"], "readwrite");
      let store = trans.objectStore("secondaryBehaviours");

      secondaryBehaviours.forEach((secondaryBehaviour) => {
        store.put(secondaryBehaviour);
      });
    });
  },

  async getSecondaryBehaviours() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["secondaryBehaviours"], "readonly");
      trans.oncomplete = () => {
        resolve(secondaryBehaviours);
      };

      let store = trans.objectStore("secondaryBehaviours");
      let secondaryBehaviours = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          secondaryBehaviours.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
};
