import VueCookies from "vue-cookies";
import axios from "axios";
import atlasSquarePointRepository from "../idb/repositories/atlasSquarePointRepository";
import generalAtlasSquareStatisticsRepository from "../idb/repositories/generalAtlasSquareStatisticRepository";
import specieRankingRepository from "../idb/repositories/SpecieRankingRepository";
import ageRepository from "../idb/repositories/ageRepository";
import siteRepository from "../idb/repositories/siteRepository";
import speciesRepository from "../idb/repositories/specieRepository";
import plumageRepository from "../idb/repositories/plumageRepository";
import primaryBehaviourRepository from "../idb/repositories/primaryBehaviourRepository";
import secondaryBehaviourRepository from "../idb/repositories/secondaryBehaviourRepository";
import directionRepository from "../idb/repositories/directionRepository";
import sitesChecksumRepository from "../idb/repositories/sitesChecksumRepository";
import speciesChecksumRepository from "../idb/repositories/speciesChecksumRepository";
import atlasSquareRepository from "../idb/repositories/atlasSquareRepository";
import collectionRepository from "../idb/repositories/collectionRepository";
import atlas32Squares from "@/assets/json/atlas_squares/atlas_squares_utm_32.json";
import atlas33Squares from "@/assets/json/atlas_squares/atlas_squares_utm_33.json";
import idb from "../idb/idb";
import router from "../router";

const cookieTitle = "synched";
const storeNames = [
  "ages",
  "plumages",
  "species",
  "collections",
  "observations",
  "countPoints",
];

export default {
  async runSynchIfNeeded() {
    console.log("Checking if synch is needed");
    // Determines if a synch is needed, and calls fullSynch if it is
    let synchNeeded = false;
    await this.isSynchNeeded().then((response) => {
      if (response == true) {
        synchNeeded = true;
      }
    });
    if (synchNeeded) {
      await this.fullSynch();
    }
  },

  async restoreOfflineDataByStoreIfNeededOrDoBackup() {
    for (const storeName of storeNames) {
      // Open the IndexedDB database
      const db = await idb.getDb();
      let storeData = await this.getAllDataFromStore(db, storeName);
      const isDataAvailable = storeData && storeData.length > 0;
      if (!isDataAvailable) {
        const cache = await caches.open("spontanCache");
        const cachedStoreData = await cache.match("/" + storeName);
        if (cachedStoreData) {
          const storeDataArray = await cachedStoreData.json();
          if (storeDataArray && storeDataArray.length > 0) {
            let trans = db.transaction([storeName], "readwrite");
            let store = trans.objectStore(storeName);
            storeDataArray.forEach((data) => {
              // Check if data has a datetime as UTC string needing conversion to localized date
              if (
                typeof data.datetime === "string" &&
                !isNaN(Date.parse(data.datetime))
              ) {
                const dateLocal = new Date(data.datetime);
                data.datetime = dateLocal;
              }

              store.put(data);
            });
          } else {
            console.log("No cached response found");
          }
        } else {
          console.log("could not retrieve data from cache");
        }
      } else {
        console.log("backupLocalData() is called");
        this.backupLocalData(storeName);
      }
    }
  },

  async getAllDataFromStore(db, storeName) {
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(storeName, "readonly");
      const objectStore = transaction.objectStore(storeName);
      const request = objectStore.getAll();
      request.onerror = () => {
        reject(new Error("Failed to get data"));
      };
      request.onsuccess = () => {
        resolve(request.result);
      };
    });
  },

  // Backup the IndexedDB data to the Service Worker Cache, so it can be restored in case IndexedDB goes missing
  async backupLocalData(storeName) {
    try {
      console.log("backup local data");
      // Open the IndexedDB database
      const db = await idb.getDb();

      // Get all data from the store
      try {
        const data = await this.getAllDataFromStore(db, storeName);
        // handle the data
        // Store the data in the Service Worker Cache
        const cache = await caches.open("spontanCache");
        const response = new Response(JSON.stringify(data));
        await cache.put("/" + storeName, response);
      } catch (error) {
        console.error("An error occurred while getting data:", error);
        // handle the error
      }

      console.log("backed data up successfully!");
    } catch (error) {
      console.log("error when backing up local data" + error);
    }
  },

  async isSynchNeeded() {
    console.log("isSynchNeeded");

    // Check if the user is online, if not, return false
    if (!navigator.onLine) {
      console.log("not online");
      return false;
    }

    // Returns true if synch is needed, otherwise false
    return $cookies.get(cookieTitle) ? false : true;
  },

  getAtlasSquarePoints() {
    return axios.get("/spontan/atlas-squares/points", { timeout: 10000 });
  },
  getSpecieRankings() {
    return axios.get("/spontan/statistics/general/species", { timeout: 10000 });
  },
  getGeneralAtlasSquareStatistics() {
    return axios.get("/spontan/statistics/general", { timeout: 10000 });
  },
  getAges() {
    return axios.get("/public/ages", { timeout: 10000 });
  },
  getPlumages() {
    return axios.get("/public/plumages", { timeout: 10000 });
  },
  getSpecies() {
    return axios.get("/public/species", { timeout: 20000 });
  },
  getSites() {
    return axios.get("/public/sites", { timeout: 30000 });
  },
  getPrimaryBehaviours() {
    return axios.get("/public/behaviours/primary", { timeout: 10000 });
  },
  getSecondaryBehaviours() {
    return axios.get("/public/behaviours/secondary", { timeout: 10000 });
  },
  getDirections() {
    return axios.get("/public/behaviours/direction", { timeout: 10000 });
  },
  getSpeciesChecksum() {
    return axios.get("/public/species/checksum", { timeout: 10000 });
  },
  getSitesChecksum() {
    return axios.get("/public/sites/checksum", { timeout: 10000 });
  },

  async fullSynch() {
    // Do full synchronization of all data necessary for the app to function properly
    console.log("fullSynch()");

    // atlas squares - check if there's any in the store, and if not, insert again
    let amount = await atlasSquareRepository.getAtlasSquaresCount();
    if (amount == null || amount < 1) {
      console.log("inserting atlas squares");
      atlasSquareRepository.insertAtlasSquares(atlas32Squares);
      atlasSquareRepository.insertAtlasSquares(atlas33Squares);
    }

    // atlasSquarePoints
    await this.getAtlasSquarePoints()
      .then((response) => {
        const isDataAvailable = response.data && response.data.length;
        if (isDataAvailable) {
          console.log("data received");
          atlasSquarePointRepository.clearAll();
          atlasSquarePointRepository.insertAtlasSquarePoints(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    // generalAtlasSquareStatistics
    await this.getGeneralAtlasSquareStatistics()
      .then((response) => {
        const isDataAvailable = response.data && response.data.length;
        if (isDataAvailable) {
          generalAtlasSquareStatisticsRepository.clearAll();
          generalAtlasSquareStatisticsRepository.insertGeneralAtlasSquareStatistics(
            response.data
          );
        }
      })
      .catch((error) => {
        console.error(error);
      });

    await this.getPrimaryBehaviours()
      .then((response) => {
        const isDataAvailable = response.data && response.data.length;
        if (isDataAvailable) {
          primaryBehaviourRepository.clearAll();
          primaryBehaviourRepository.insertPrimaryBehaviours(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    await this.getSecondaryBehaviours()
      .then((response) => {
        const isDataAvailable = response.data && response.data.length;
        if (isDataAvailable) {
          secondaryBehaviourRepository.clearAll();
          secondaryBehaviourRepository.insertSecondaryBehaviours(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    await this.getDirections()
      .then((response) => {
        const isDataAvailable = response.data && response.data.length;
        if (isDataAvailable) {
          directionRepository.clearAll();
          directionRepository.insertDirections(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    await this.getAges()
      .then((response) => {
        const isDataAvailable = response.data && response.data.length;
        if (isDataAvailable) {
          ageRepository.clearAll();
          ageRepository.insertAges(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    await this.getPlumages()
      .then((response) => {
        const isDataAvailable = response.data && response.data.length;
        if (isDataAvailable) {
          plumageRepository.clearAll();
          plumageRepository.insertPlumages(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    // specieRankings
    await this.getSpecieRankings()
      .then((response) => {
        const isDataAvailable = response.data && response.data.length;
        if (isDataAvailable) {
          specieRankingRepository.clearAll();
          specieRankingRepository.insertSpecieRankings(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    let updateSites = true;
    let siteChecksums = await sitesChecksumRepository.getSitesChecksum();
    await this.getSitesChecksum()
      .then((response) => {
        if (typeof siteChecksums[0] !== "undefined") {
          if (response.data == siteChecksums[0].checksum) {
            updateSites = false;
          } else {
            sitesChecksumRepository.insertSitesChecksum({
              checksum: response.data,
            });
          }
        } else {
          sitesChecksumRepository.insertSitesChecksum({
            checksum: response.data,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
    let sitesCount = await siteRepository.getSitesCount();
    if (sitesCount == null || sitesCount < 1) {
      updateSites = true;
    }
    if (updateSites === true) {
      console.log("updating sites");
      await this.getSites()
        .then((response) => {
          const isDataAvailable = response.data && response.data.length;
          if (isDataAvailable) {
            siteRepository.clearAll();
            siteRepository.insertSites(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    let updateSpecies = true;
    let speciesChecksum = await speciesChecksumRepository.getSpeciesChecksum();
    await this.getSpeciesChecksum()
      .then((response) => {
        if (typeof speciesChecksum[0] !== "undefined") {
          // checksum is up to date
          if (response.data == speciesChecksum[0].checksum) {
            updateSpecies = false;
          } else {
            speciesChecksumRepository.insertSpeciesChecksum({
              checksum: response.data,
            });
          }
        } else {
          speciesChecksumRepository.insertSpeciesChecksum({
            checksum: response.data,
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });

    let speciesCount = await speciesRepository
      .getSpeciesCount()
      .catch((error) => {
        console.error(error);
      });
    if (updateSpecies === true || speciesCount == null || speciesCount < 1) {
      console.log("updating species");
      await this.getSpecies()
        .then((response) => {
          const isDataAvailable = response.data && response.data.length;
          if (isDataAvailable) {
            speciesRepository.clearAll();
            speciesRepository.insertSpecies(response.data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    // After full synch is run, set cookie to indicate synch is done
    await this.setSynchCookie();
  },

  setSynchCookie() {
    // Will set a cookie called synched with expiration set to midnight
    // This cookie will be used to determine if a new synch is needed in case of the cookies absence
    console.log("setSynchCookie");
    let midnight = new Date();
    midnight.setHours(23, 59, 59, 0);
    $cookies.set(cookieTitle, "true", midnight);
  },
};
