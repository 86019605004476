import idb from "../idb.js";

export default {
  async insertDirection(direction) {
    let db = await idb.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["directions"], "readwrite");
      let store = trans.objectStore("directions");

      store.put(direction);
    });
  },

	async clearAll() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["directions"], "readwrite");
      let store = trans.objectStore("directions");
      store.clear();
    });
  },

  async insertDirections(directions) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["directions"], "readwrite");
      let store = trans.objectStore("directions");

      directions.forEach((direction) => {
        store.put(direction);
      });
    });
  },

  async getDirections() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["directions"], "readonly");
      trans.oncomplete = () => {
        resolve(directions);
      };

      let store = trans.objectStore("directions");
      let directions = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          directions.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
};
