import idb from "../idb.js";

export default {
  async insertPlumage(plumage) {
    let db = await idb.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["plumages"], "readwrite");
      let store = trans.objectStore("plumages");

      store.put(plumage);
    });
  },

	async clearAll() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["plumages"], "readwrite");
      let store = trans.objectStore("plumages");
      store.clear();
    });
  },

  async insertPlumages(plumages) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["plumages"], "readwrite");
      let store = trans.objectStore("plumages");

      plumages.forEach((plumage) => {
        store.put(plumage);
      });
    });
  },

  async getPlumages() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["plumages"], "readonly");
      trans.oncomplete = () => {
        resolve(plumages);
      };

      let store = trans.objectStore("plumages");
      let plumages = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          plumages.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
};
