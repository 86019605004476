import idb from "../idb.js";

export default {
  async clearAll() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["ages"], "readwrite");
      let store = trans.objectStore("ages");
      store.clear();
    });
  },

  async insertAge(age) {
    let db = await idb.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["ages"], "readwrite");
      let store = trans.objectStore("ages");

      store.put(age);
    });
  },

  async insertAges(ages) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["ages"], "readwrite");
      let store = trans.objectStore("ages");

      ages.forEach((age) => {
        store.put(age);
      });
    });
  },

  async getAges() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["ages"], "readonly");
      trans.oncomplete = () => {
        resolve(ages);
      };

      let store = trans.objectStore("ages");
      let ages = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          ages.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
};
