window.onload = function () {
  // Initialize deferredPrompt for use later to show browser install prompt.
  let deferredPrompt;
  // Initialize the install button and its container from the document page
  let buttonInstall = document.getElementById("install-app-btn");
  let buttonInstallDiv = document.getElementById("install-app-btn-container");
  let isInstallable = false;

  // Check if client is a iOS (iPhone or iPad) device, if it's webkit browser (Safari)
  // and then finally check if the client is both iOS and webkit
  var ua = window.navigator.userAgent;
  var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  var webkit = !!ua.match(/WebKit/i);
  var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

  let installInstructionsIosSafariDiv = document.getElementById(
    "ios-webkit-install-instructions-container"
  );
  let browserSuggestionDiv = document.getElementById(
    "pwa-browser-compability-container"
  );
  // If this is iOSSafari show the install instructions div
  if (iOSSafari) {
    installInstructionsIosSafariDiv.style.display = "block";
  } else if (iOS) {
    // If it's iOS but not with webkit, then show div suggesting to install with Safari
    browserSuggestionDiv.style.display = "block";
  }

  window.addEventListener("beforeinstallprompt", (e) => {
    isInstallable = true;
    // Show the install button
    buttonInstallDiv.style.display = "block";
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
  });

  buttonInstall.addEventListener("click", async () => {
    // Show the install prompt
    if (deferredPrompt !== null) {
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === "accepted") {
        deferredPrompt = null;
      }
    }
  });

  window.addEventListener("appinstalled", () => {
    buttonInstallDiv.style.display = "none";
    // Clear the deferredPrompt so it can be garbage collected
    deferredPrompt = null;
    // Optionally, send analytics event to indicate successful install
    console.log("PWA was installed");
  });

  // Check if app is not displayed in standalone window (installed and used as an app)
  if (!window.matchMedia("(display-mode: standalone)").matches) {
    console.log("App is not shown in PWA window");
  }
};
