import idb from "../idb.js";

export default {
  async insertPrimaryBehaviour(primaryBehaviour) {
    let db = await idb.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["primaryBehaviours"], "readwrite");
      let store = trans.objectStore("primaryBehaviours");

      store.put(secondaryBehaviour);
    });
  },

  async getPrimaryBehaviourCount() {
    let count = await idb.getCountByObjectStore("primaryBehaviours");
    return count.result;
  },

  async clearAll() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["primaryBehaviours"], "readwrite");
      let store = trans.objectStore("primaryBehaviours");
      store.clear();
    });
  },

  async insertPrimaryBehaviours(primaryBehaviours) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["primaryBehaviours"], "readwrite");
      let store = trans.objectStore("primaryBehaviours");

      primaryBehaviours.forEach((primaryBehaviour) => {
        store.put(primaryBehaviour);
      });
    });
  },

  async getPrimaryBehaviours() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["primaryBehaviours"], "readonly");
      trans.oncomplete = () => {
        resolve(primaryBehaviours);
      };

      let store = trans.objectStore("primaryBehaviours");
      let primaryBehaviours = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          primaryBehaviours.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
};
