import idb from "../idb.js";

export default {
  async insertSpecieRankings(specieRankings) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["specieRankings"], "readwrite");
      let store = trans.objectStore("specieRankings");

      specieRankings.forEach((specieRanking) => {
        store.put(specieRanking);
      });
    });
  },
  

  async clearAll() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["specieRankings"], "readwrite");
      let store = trans.objectStore("specieRankings");
      store.clear();
    });
  },

  async getSpecieRankings() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["specieRankings"], "readonly");
      trans.oncomplete = () => {
        resolve(specieRankings);
      };

      let store = trans.objectStore("specieRankings");
      let specieRankings = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          specieRankings.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
};
