import idb from "../idb.js";

export default {
  async insertCollection(collection) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["collections"], "readwrite");
      let store = trans.objectStore("collections");

      let request = store.put(collection);
      request.onsuccess = function (e) {
        resolve(e.target.result);
      };
    });
  },

  async deleteSynchedCollections() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let transaction = db.transaction("collections", "readwrite");
      let objectStore = transaction.objectStore("collections");
      let request = objectStore.openCursor();

      request.onsuccess = (event) => {
        let cursor = event.target.result;
        if (cursor) {
          let collection = cursor.value;
          if (!("unsynched" in collection) || collection.unsynched === false) {
            objectStore.delete(cursor.primaryKey);
          }
          cursor.continue();
        } else {
          resolve();
        }
      };
    });
  },

  async getCollectionCount() {
    let count = await idb.getCountByObjectStore("collections");
    return count.result;
  },

  async deleteCollectionById(id) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let request = db
        .transaction("collections", "readwrite")
        .objectStore("collections")
        .delete(+id);

      request.onsuccess = (e) => {
        resolve(e.target.result);
      };
    });
  },
  async getCollections() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["collections"], "readonly");
      trans.oncomplete = () => {
        resolve(collections);
      };

      let store = trans.objectStore("collections");
      let index = store.index("date");
      let collections = [];

      let cursorRequest = index.openCursor(null, "prev");
      cursorRequest.onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          collections.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
  async getCollection(id) {
    let numberId = parseInt(id, 10);
    let db = await idb.getDb();
    let trans = db.transaction(["collections"], "readonly");
    let store = trans.objectStore("collections");
    let collection = store.get(numberId);

    return new Promise((resolve) => {
      collection.onsuccess = () => {
        resolve(collection.result);
      };
    });
  },
};
