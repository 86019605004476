import idb from "../idb.js";

export default {
  async insertSite(site) {
    let db = await idb.getDb();
    return new Promise((resolve) => {
      let trans = db.transaction(["sites"], "readwrite");
      let store = trans.objectStore("sites");

      store.put(site);
    });
  },

  async getSitesCount() {
    let count = await idb.getCountByObjectStore("sites");
    return count.result;
  },

  async clearAll() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["sites"], "readwrite");
      let store = trans.objectStore("sites");
      store.clear();
    });
  },

  async insertSites(sites) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["sites"], "readwrite");
      let store = trans.objectStore("sites");

      sites.forEach((site) => {
        store.put(site);
      });
    });
  },

  async getSites() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["sites"], "readonly");
      trans.oncomplete = () => {
        resolve(sites);
      };

      let store = trans.objectStore("sites");
      let sites = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          sites.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
};
