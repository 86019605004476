import idb from "../idb.js";

export default {
  async insertAtlasSquarePoints(atlasSquarePoints) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["atlasSquarePoints"], "readwrite");
      let store = trans.objectStore("atlasSquarePoints");

      atlasSquarePoints.forEach((atlasSquarePoint) => {
        store.put(atlasSquarePoint);
      });
    });
  },

  async getAtlasSquarePointByAtlasSquareNumber(squareNumber) {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["atlasSquarePoints"], "readonly");
      trans.oncomplete = () => {
        resolve(atlasSquarePoints);
      };

      let store = trans.objectStore("atlasSquarePoints");
      let atlasSquarePoints = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          if (cursor.value.kvadratnr == squareNumber) {
            atlasSquarePoints.push(cursor.value);
          }
          cursor.continue();
        }
      };
    });
  },

  async clearAll() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["atlasSquarePoints"], "readwrite");
      let store = trans.objectStore("atlasSquarePoints");
      store.clear();
    });
  },

  async getAtlasSquarePoints() {
    let db = await idb.getDb();

    return new Promise((resolve) => {
      let trans = db.transaction(["atlasSquarePoints"], "readonly");
      trans.oncomplete = () => {
        resolve(atlasSquarePoints);
      };

      let store = trans.objectStore("atlasSquarePoints");
      let atlasSquarePoints = [];

      store.openCursor().onsuccess = (e) => {
        let cursor = e.target.result;
        if (cursor) {
          atlasSquarePoints.push(cursor.value);
          cursor.continue();
        }
      };
    });
  },
};
